@font-face{
    font-family: BarlowSemiCondensed;
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: BarlowSemiCondensed;
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: BarlowSemiCondensed;
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: BarlowSemiCondensed;
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: BarlowSemiCondensed;
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: BarlowSemiCondensed;
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: BarlowSemiCondensed;
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face{
    font-family: Hidayatullah;
    src: url('../fonts/Hidayatullah/hidayatullahDEMO.ttf');
    font-weight: 500;
    font-display: swap;
}

:root{
    --primary-ss-color:#045a4f; 
    --secondary-ss-color: #fcb254;
}

*{
    padding: 0;
    margin: 0;
}

body{
    font-family: BarlowSemiCondensed, sans-serif !important;
    color: black;
}

.text-barlow{
    font-family: BarlowSemiCondensed, sans-serif !important;
}

.text-hidayatullah{
    font-family: Hidayatullah, sans-serif !important;
}

.text-ss-primary{
    color: var(--primary-ss-color) !important;
}

.text-ss-secondary{
    color: var(--secondary-ss-color) !important;
}

.hover-ss-primary{
    color: var(--primary-ss-color);
}

.hover-ss-primary:hover{
    color: var(--secondary-ss-color) ;
}

.hover-ss-secondary{
    color: var(--secondary-ss-color);
}


.hover-ss-secondary:hover{
    color: var(--primary-ss-color);
}

.hover-ss-white{
    color: white !important;
}

.hover-ss-white:hover{
    color: var(--secondary-ss-color) !important;
}

.bg-ss-primary{
    background-color: var(--primary-ss-color);
}
.bg-ss-secondary{
    background-color: var(--secondary-ss-color);
}

.fs-ss-92{
    font-size: 92px;
}

.fs-ss-68{
    font-size: 68px;
}

.fs-ss-64{
    font-size: 64px;
}

.fs-ss-58{
    font-size:58px;
}

.fs-ss-56{
    font-size:56px;
}
.fs-ss-52{
    font-size:52px;
}

.fs-ss-48{
    font-size: 48px;
}

.fs-ss-40{
    font-size: 40px;
}
.fs-ss-35{
    font-size: 35px;
}

.fs-ss-32{
    font-size: 32px;
}

.fs-ss-30{
    font-size: 30px;
}

.fs-ss-28{
    font-size: 28px;
}
.fs-ss-26{
    font-size: 26px;
}
.fs-ss-24{
    font-size: 24px;
}
.fs-ss-22{
    font-size: 22px;
}

.fs-ss-20{
    font-size: 20px;
}

.fs-ss-18{
    font-size: 18px;
}

.fs-ss-16{
    font-size: 16px;
}
.fs-ss-17{
    font-size: 17px;
}
.fs-ss-15{
    font-size: 15px;
}

.fs-ss-13{
    font-size: 13px;
}

.fs-ss-14{
    font-size: 14px;
}

.logo-img{
    height:60px;
}

.accordion-button{
    padding: 10px 20px !important;
}
.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #ededed !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.accordion{
    --bs-accordion-border-color: none !important ;
}

.btn-ss-primary{
    background-color: #1e80c9;
    cursor: pointer;
}

.btn-ss-primary:hover{
    background-color: #1e7fc9d2 !important;
    cursor: pointer;
}

.btn-ss-primary-light{
    background-color: rgb(112, 189, 248);
    cursor: pointer;
    padding-top: 2px;
}

.btn-ss-primary-light:hover{
    background-color: rgba(112, 189, 248, 0.712) !important;
    cursor: pointer;
}

.btn-ss-secondary{
    background-color: var(--secondary-ss-color);
    color: #fff;
    cursor: pointer;
}

.shadow-ss-1{
    box-shadow: 2px 3px 3px 2px #dadada !important;
}

.shadow-ss-2{
    box-shadow: 2px 5px 5px 4px #dadada !important
}

.shadow-ss-1-btn{
    box-shadow: 1px 1px 10px 0 #dadada80 !important;
}

.fw-900{
    font-weight: 900;
}

.fw-800{
    font-weight: 800;
}

.fw-700{
    font-weight: 700;
}


.fw-600{
    font-weight: 600;
}

.fw-500{
    font-weight: 500;
}

.fw-400{
    font-weight: 400;
}

.lh-44{
    line-height: 44px;
}

@media screen and (max-width: 1199px) {
    .header-logo{
        width: 180px !important;
    }
}

@media (min-width: 991px) and (max-width: 1199px) {
    #website-responsive .container-lg {
        max-width: 1080px;
    }
}

@media screen and (max-width: 992px) {
    .header-logo{
        width: 200px !important;
    }

    .bg-ramadan-2 {
        background-image: none !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 24vh !important;
    }
    
    .bg-middle-banner{
        background-position: right !important;
    }

    .rounded-200{
        border-radius: 50px !important;
    }

    .col-custom-contact{
        width: 30% !important;
    }
    
    #donate .fs-ss-24{
        font-size: 18px !important;
    }

    #donate .fs-ss-links{
        font-size: 15px !important;
    }

    .w-video{
        width: 100% !important;
        height: 300px !important;
    }

    .bg-ss-offcanvas-primary{
        background-color: var(--primary-ss-color);
    }

    .bg-home-banner{
        background-image: url(../../assets/images/home/HomeBanner.webp);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        /* height: 50vh !important; */
    }

    .fs-banner{
        position: absolute;
        top: 35% !important;
        left: 10%;
    }
    

    .fs-banner-title{
        font-size: 48px !important;
        letter-spacing: 0.3vw !important;
        line-height: 60px !important ;
    }
}

@media screen and (max-width: 768px) {
    .bg-middle-banner{
        background-position: right !important;
    }

    .bg-ramadan-2 {
        /* background-image: url(../images/ramadan/BgPattern.webp); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: auto !important;
    }

    .bg-footer{
        background-image: url(../images/home/BgFooterMobile.webp) !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
    }
    
    .w-50 {
        width: 100% !important;
    }

    .center{
        margin-left: auto;
        margin-right: auto;
    }

    .rounded-200{
        border-radius: 50px !important;
    }

    .mb-7{
        margin-top: 0px !important ;
        margin-bottom: 0px !important ;
    }

    .border-left-right{
        border-right: none !important;
    }

    .col-custom-contact{
        width: 80% !important;
    }
    
    .border-left{
        border-left: 0px !important ;
    }

    #middle-banner .fs-ss-20{
        font-size: 16px !important;
    }
    
    .w-video{
        width: 100% !important;
        height: 200px !important;
    }

    .fs-middle-banner{
        font-size: 35px !important;
    }
    
    .fs-sub-middle-banner{
        font-size: 20px !important;
    }

    .fs-ss-links{
        font-size: 16px !important;
        letter-spacing: 0.09vw;
    }

    .border-end-secondary{
        border-right: none !important ;
    }

    .bg-home-banner{
        background-image: url(../../assets/images/home/HomeBanner.webp);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        height: 50vh !important;
    }

    .fs-banner{
        position: absolute;
        top: 35% !important;
        left: 10%;
    }
    

    .fs-banner-title{
        font-size: 35px !important;
        letter-spacing: 0.3vw !important;
        line-height: 40px !important ;
    }
    
    .fs-banner-sub-title{
        font-size: 20px !important;
        letter-spacing: 0.1vw;
    }
}

@media screen and (max-width: 576px) {
    #website-responsive .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .fs-ss-68{
        font-size: 36px;
    }
    
    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:28px;
    }
    #website-responsive .fs-ss-52{
        font-size:30px;
    }
    
    #website-responsive .fs-ss-48{
        font-size: 24px;
    }

    #website-responsive .fs-ss-40{
        font-size: 24px;
    }

    #website-responsive .fs-ss-35{
        font-size: 22px;
    }

    
    #website-responsive .fs-ss-32{
        font-size: 24px;
    }

    #website-responsive .fs-ss-30, #website-responsive .fs-ss-28{
        font-size: 18px;
    }

    #website-responsive .fs-ss-26, #website-responsive .fs-ss-24{
        font-size: 19px;
    }

    #website-responsive .fs-ss-22{
        font-size: 18px;
    }
    #website-responsive .fs-ss-18, #website-responsive .fs-ss-17{
        font-size:16px;
    }
}





/* Call Genie */
.header-logo{
    width: 200px;
    height: auto;
}

.footer-logo{
    width: 250px;
    height: auto; 
}

.offcanvas-logo{
    width: 230px;
    height: auto;    
}

.right-menu{
    display: none;
}

.right-menu:hover .dropdown-menu{
    display: block;
}

.w-services{
    width: 200px ;
    height: auto;
}

.bg-middle-banner{
    /* background-image: url(../images/home/BgMiddleBanner.webp); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
}

.bg-service{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.bg-home-banner{
    background-image: url(../../assets/images/home/HomeBanner.webp);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
}


/* Huntsville Islamic Center */
.w-content{
    width: max-content;
}

.fs-ss-links{
    font-size: 1.2vw;
    letter-spacing: 0.09vw;
}

.bg-ss-transparent{
    background-color: transparent !important;
}

.fs-banner{
    position: absolute;
    top: 30%;
    left: 10%;
}

.fs-banner-title{
    font-size: 5.8vw;
    letter-spacing: 0.2vw;
    line-height: 5.8vw ;
}

.fs-banner-sub-title{
    font-size: 3vw;
    letter-spacing: 0.1vw;
}

.fs-middle-banner{
    font-size: 5vw;
}

.fs-sub-middle-banner{
    font-size: 2vw;
}

.bg-middle-banner{
    background-image: url(../images/home/MiddleBanner.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-footer{
    background-image: url(../images/home/BgFooter.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.btn-padding{
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 2px;
}

.btn-padding-middle{
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 2px;
}

.btn-padding-donate{
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 2px;
}


.border-0{
    border: 0px !important;
}

.input-focus:focus{
    box-shadow: none !important;
}

.bg-events{
    background-color: rgb(236, 236, 236);
}

.w-100{
    width: 100% !important;
}

.h-100{
    height: auto !important;
}

.bg-above-footer{
    background-image: url(../images/home/BgAboveFooter.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-mosque{
    background-image: url(../images/home/Mosque.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

.border-end-secondary{
    border-right: 1px solid var(--secondary-ss-color) ;
}

.shadow-ss-1-video{
    box-shadow: 2px 3px 10px 2px #616161 !important;
}

.toggle-icon{
    box-shadow: none !important;
}

.btn-close{
    background-color: white;
    box-shadow: none !important;
}

.w-video{
    width: 877px;
    height: 500px;
}

.bg-common-banner{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.w-icons{
    width: 80px;
}

.w-icons2{
    width: 50px
}

.slick-prev{
    color: #045a4f !important;
    z-index: 1 !important;
    left: 0px !important;
}

.bg-ss-light{
    background-color: #fcb35438;
}

.slick-prev::before{
    color: var(--primary-ss-color) !important;
    z-index: 100 !important;
}

.slick-next::before{
    color: var(--primary-ss-color) !important;
    z-index: 100 !important;
} 

.slick-prev{
    color: var(--primary-ss-color) !important;
    z-index: 100 !important;
    left: -2% !important;
}

.slick-next{
    color: var(--primary-ss-color) !important;
    z-index: 100 !important;
    right: -2% !important;
} 


.shadow-ss-below{
    box-shadow: -0px 3px 5px 2px #dadada !important;
}

.border-left-right{
    /* border-left: 3px solid var(--secondary-ss-color); */
    border-right: 3px solid var(--secondary-ss-color);
}

.underline-3{
    font-size: 3vw;
    position: absolute;
    right: 18%;
    bottom: 30%;
}

.position-relative{
    position: relative;
}

.cursor{
    cursor: pointer;
    background-color: transparent !important;
}

.react-tabs__tab--selected{
    border-right: 8px solid var(--secondary-ss-color) !important;
    border-radius: 0px !important;
    color: var(--primary-ss-color) !important;
}

.react-tabs__tab--selected .fs-ss-35{
    color: var(--secondary-ss-color) !important;
}

.border-right{
    border-right: 8px solid grey ;
}

.border-left{
    border-left: 6px solid grey ;
}

.styles-sc-18e57uo-0{
    display : none !important;
}

.cursor-pointer{
    cursor: pointer;
}

.col-upcoming-events{
    width: 30% ;
}

.sticky-top{
    position: sticky;
    top: 30px ;
}

/* .position-absolute-modal{
    position: absolute;
} */

.w-90{
    width: 90%;
}

.text-underline{
    text-decoration: underline;
}

.col-custom-contact{
    width: 23%;
}

.bg-pattern{
    background-image: url(../images/home/Pattern1.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover ;    
}

.border-primary{
    border-color: var(--secondary-ss-color) !important;
}

.mb-7{
    margin-top: 40px ;
    margin-bottom: 40px ;
}

.align-text-line {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important; 
    overflow: hidden !important;
    }


    @media screen and (min-width: 2500px){
        
    }

    #events .nav-tabs {
        --bs-nav-tabs-border-width: none !important;
    }
    
    #events .nav-tabs .nav-link {
        color: black !important;
    }
    
    
    #events .react-tabs__tab--selected{
        background-color: var(--secondary-ss-color) !important;
        border-radius: 100px !important ;
    }
    
    #events .react-tabs__tab--selected .nav-link{
        color: black !important;
    }
    
    #events .react-tabs__tab{
        background-color: var(--primary-ss-color) !important;
    }

    .bg-join-team {
        background-image: url(../images/BgTeam.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .rounded-200{
        border-radius: 200px;
    }

    
  /* Remove background color from the flag */
.react-tel-input .flag-dropdown {
    background-color: white !important;
    border: none !important;
    border-radius: 0px !important;
  }
  
  /* Remove hover background color from the flag */
  .react-tel-input .flag-dropdown:hover {
    background-color: white !important;
  }
  
  /* Customize the input field */
  .react-tel-input .form-control {
    border: none !important;       /* Remove border */
    font-size: 16px !important;    /* Set font size */
    padding: 0.5rem;               /* Optional: Adjust padding */
  }
  
  /* Customize the dropdown menu */
  .react-tel-input .country-list {
    background-color: white !important;  /* Set background color to white */
    color: black !important;             /* Set text color to black */
    text-align: left !important;         /* Align text to the left */
    font-size: 16px !important;          /* Match font size in dropdown */
    border: 1px solid #ced4da !important; /* Optional: Add border for a clean look */
    border-radius: 4px !important;       /* Optional: Add border-radius */
    max-height: 300px;                   /* Optional: Add scroll limit */
    overflow-y: auto;                    /* Enable scrolling for long dropdowns */
  }
  
  /* Style individual items in the dropdown */
  .react-tel-input .country-list .country {
    padding: 0.5rem !important;           /* Adjust padding for dropdown items */
    cursor: pointer;                      /* Pointer cursor for better UX */
    color: black !important;              /* Text color for items */
    background-color: white !important;   /* Ensure consistent white background */
  }
  
  /* Hover effect for dropdown items */
  .react-tel-input .country-list .country:hover {
    background-color: #f8f9fa !important; /* Light grey hover effect */
    color: black !important;              /* Keep text color black */
  }
  
  /* Remove focus border on the input field */
  .react-tel-input .form-control:focus {
    outline: none !important;      /* Remove focus outline */
    box-shadow: none !important;   /* Remove shadow */
  }

  .react-tel-input .form-control {
    width: 100% !important;
    font-size: 18px !important ;
  }

  .open{
    border-radius: 0px !important;
  }

  .hover-ss-black-primary {
    color: black;
  }

  .hover-ss-black-primary:hover {
    color: var(--primary-ss-color) !important;
  }

  .hover-ss-donate{
    background-color: var(--secondary-ss-color);
  }

  .hover-ss-donate:hover{
    background-color: #ffa93c ;
  }

  .hover-bg-ss-white-primary{
    background-color: white;
  }

  .hover-bg-ss-white-primary:hover{
    background-color: var(--secondary-ss-color) ;
  }
  

  .modal-lg {
    --bs-modal-width: 570px !important;
}

  .hover-social-media {
    transition: transform 0.3s ease-in-out; /* Smooth zoom transition */
  }
  
  .hover-social-media:hover {
    transform: scale(1.1); /* Zoom in effect */
  }
  

  .react-tabs__tab--selected:focus-visible{
    outline: 0px !important;
  }
  

  .bg-grey2{
    background-color: #dddddd;
  }

  .bg-grey{
    background-color: #dddddd;
  }

  .bg-grey:hover{
    background-color: var(--secondary-ss-color);
  }

  #ramadan .hover-ss-donate {
    background-color: var(--secondary-ss-color) !important;
  }

  #ramadan .hover-ss-donate:hover {
    background-color: #ffa93c !important;
  }
  
  .bg-ramadan-1 {
    background-image: url(../images/ramadan/Bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-ramadan-2 {
    /* background-image: url(../images/ramadan/BgPattern.webp); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 40vh;
}

.accordion-item {
    border: 2px solid grey !important;
}

#faqAccordion .accordion-button:not(.collapsed) {
    color: var(--primary-ss-color);
    background-color: transparent !important;
    border-radius: 0px !important;
}

.ls-0 {
    letter-spacing: 0px;
}

#ramadan .btn-padding-middle{
    padding-left: 70px !important;
    padding-right: 70px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}